import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { getAuth } from '../config';

export default function useLogout() {
  const history = useHistory();
  const logout = useCallback(async () => {
    history.replace('/login');
    getAuth().signOut();
  }, [history]);

  return logout;
}
