import PropTypes from 'prop-types';
import { Avatar, Col, Row, Table } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import qs from 'query-string';

export default function UsersList({ isLoading, value, actions }) {
  const items = value || [];
  return (
    <Table
      loading={isLoading}
      dataSource={items}
      rowKey="id"
      pagination={{ defaultPageSize: 20 }}
      itemLayout="horizontal"
      columns={[
        {
          title: 'Avatar',
          dataIndex: 'email',
          render: email => <Avatar>{email?.charAt(0)}</Avatar>,
        },
        {
          title: 'Email',
          dataIndex: 'id',
          render: (_, record) => record.email,
        },
        {
          title: 'Nome',
          dataIndex: 'displayName',
        },
        {
          title: 'Azioni',
          render: (_, record) => (
            <Row align="middle" gutter={16} type="flex">
              {actions(record).map((a, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Col key={i}>{a}</Col>
              ))}
            </Row>
          ),
        },
      ]}
    />
  );
}

UsersList.propTypes = {
  isLoading: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.shape({})),
  actions: PropTypes.func,
};

UsersList.defaultProps = {
  value: [],
  isLoading: true,
  actions: item => [
    <Link to={`${item.id}`}>vedi Profilo</Link>,
    <Link to={`../devices/?${qs.stringify({ owner: item.id })}`}>vedi Dispositivi</Link>,
  ],
};
