import PropTypes from 'prop-types';
import React from 'react';
import { getAuth } from '../config';
import useCompaniesOfUser from '../hooks/useCompaniesOfUser';

const PermissionsContext = React.createContext(null);

export const PermissionProvider = ({ children }) => {
  const uid = getAuth().currentUser ? getAuth().currentUser.uid : null;
  const { data: permissions } = useCompaniesOfUser(uid);

  return <PermissionsContext.Provider value={permissions}>{children}</PermissionsContext.Provider>;
};

export default PermissionsContext;

PermissionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
