import React from 'react';
import { PageHeader, Spin } from 'antd';
import { FirestoreCollection } from '@react-firebase/firestore';
import DevicesCard from './DevicesCard';
import UsersCard from './UsersCard';
import { IsSuperAdmin } from '../../components/IsSuperAdmin';
import useCurrentCompany from '../../hooks/useCurrentCompany';
import whereCompanyDevices from '../../queries/companyDevices';

function DashboardPage() {
  const currentCompany = useCurrentCompany();
  const title = currentCompany ? `Dashboard ${currentCompany.name}` : 'Dashboard';
  return (
    <div>
      <PageHeader title={title} />
      <IsSuperAdmin>
        <FirestoreCollection path="/devices">
          {d => (d.isLoading ? <Spin /> : <DevicesCard value={d.value} ids={d.ids} />)}
        </FirestoreCollection>
      </IsSuperAdmin>
      <IsSuperAdmin>
        <FirestoreCollection path="/users">
          {d => (d.isLoading ? <Spin /> : <UsersCard value={d.value} />)}
        </FirestoreCollection>
      </IsSuperAdmin>
      {currentCompany && (
        <FirestoreCollection
          path="/devices"
          where={whereCompanyDevices(currentCompany.id)}
        >
          {d => (d.isLoading ? <Spin /> : <DevicesCard value={d.value} ids={d.ids} />)}
        </FirestoreCollection>
      )}
    </div>
  );
}

export default DashboardPage;
