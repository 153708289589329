import { useCallback } from 'react';
import { notification } from 'antd';
import { getFirestore } from '../config';

export default function useAddUserToCompany() {
  return useCallback(async (userId, companyId, role) => {
    try {
      await getFirestore()
        .collection('users')
        .doc(userId)
        .collection('userCompanyMembership')
        .add({
          role,
          company: getFirestore()
            .collection('companies')
            .doc(companyId),
        });
    } catch (e) {
      console.log(e);
      notification.error({ message: e.message });
    }
  }, []);
}
