import { useCallback } from 'react';
import { notification } from 'antd';
import { getFirestore } from '../config';

export default function useChangeUserPermission() {
  return useCallback(async (user, path, newRole) => {
    try {
      await getFirestore()
        .collection('users')
        .doc(user)
        .collection('userCompanyMembership')
        .doc(path)
        .update({
          role: newRole,
        });
    } catch (e) {
      notification.error({ message: e.message });
    }
  }, []);
}
