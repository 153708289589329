import { useEffect, useMemo, useReducer } from 'react';
import { notification } from 'antd';
import { getFirestore } from '../config';
import emptyFn from '../utils/emptyFn';

const initialState = {
  loading: false,
  data: [],
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'startLoading':
      return { ...state, loading: true };
    case 'endLoading':
      return { ...state, loading: false, data: action.data };
    default:
      return state;
  }
};

function getRef(companyId) {
  return companyId
    ? getFirestore()
      .collection('companies')
      .doc(companyId)
    : null;
}

function useUsersInCompany(companyId) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const ref = useMemo(() => getRef(companyId), [companyId]);

  useEffect(() => {
    if (ref) {
      dispatch({ type: 'startLoading' });
      return getFirestore()
        .collectionGroup('userCompanyMembership')
        .where('company', '==', ref)
        .onSnapshot(
          async collection => {
            const { docs } = collection;
            const users = await Promise.all(
              docs.map(async doc => {
                const user = doc.ref.parent.parent;
                const u = await user.get();
                return {
                  id: user.id,
                  path: doc.id,
                  email: u.data().email,
                  role: doc.data().role,
                };
              }),
            );
            dispatch({ data: users, type: 'endLoading' });
          },
          error => {
            console.log(error);
            notification.error({ message: 'Si è verificato un errore' });
            dispatch({ data: [], type: 'endLoading' });
          },
        );
    }
    return emptyFn;
  }, [ref]);

  return state;
}

export default useUsersInCompany;
