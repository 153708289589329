import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Button, Icon, notification } from 'antd';

import { useLocation } from 'react-router-dom';
import { getAuth } from '../../config';
import firebase from 'firebase';

export default function GoogleAuth({ onSuccess }) {
  const location = useLocation();

  const onLogin = useCallback(async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      await getAuth().signInWithRedirect(provider);
    } catch (e) {
      notification.error({ message: e.message });
    }
  }, [onSuccess]);

  return (
    <Button onClick={onLogin} style={{ display: 'block', margin: 'auto' }}>
      <Icon type="google" /> Accedi con Google
    </Button>
  );
}

GoogleAuth.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
