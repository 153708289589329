import { getFirestore } from '../config';

export default function whereDeviceIsOwner(owner) {
  const ownerRef = getFirestore()
    .collection('users')
    .doc(owner);
  return {
    field: 'owner',
    op: '==',
    value: ownerRef,
  };
}
