import { getFirestore } from '../config';

export default function whereCompanyDevices(companyId, opName = 'operator') {
  return {
    field: 'authorizedSupportCompany',
    [opName]: '==',
    value: getFirestore()
      .collection('companies')
      .doc(companyId),
  };
}
