import { Input, PageHeader } from 'antd';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as qs from 'query-string';

import DevicesList from './DevicesList';
import whereDeviceIsOwner from '../../queries/deviceIsOwner';
import whereCompanyDevices from '../../queries/companyDevices';
import IsSuperAdminContext from '../../contexts/IsSuperAdmin';
import useCollectionWhere from '../../hooks/useCollectionWhere';
import { MAC_ADDR_PREFIX } from '../../constants';

export default function DeviceListPage() {
  const [query, setQuery] = useState('');
  const { companyId } = useParams();
  const history = useHistory();
  const isSuper = useContext(IsSuperAdminContext);

  const goToUsers = useCallback(() => history.push('../users/'), [history]);

  const params = qs.parse(window.location.search);
  const where = useMemo(() => {
    if (!query) {
      return [];
    }
    const complete = MAC_ADDR_PREFIX + query;
    const strlength = complete.length;
    const strFrontCode = complete.slice(0, strlength - 1);
    const strEndCode = complete.slice(strlength - 1, complete.length);
    const endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

    return [
      {
        field: 'macAddress',
        op: '>=',
        value: complete,
      },
      {
        field: 'macAddress',
        op: '<',
        value: endcode,
      },
    ];
  }, [query]);
  let hasOwnerQuery = false;

  if (params.owner) {
    where.push(whereDeviceIsOwner(params.owner));
    hasOwnerQuery = true;
  } else if (!isSuper) {
    where.push(whereCompanyDevices(companyId, 'op'));
  }

  const { data, loading } = useCollectionWhere('devices', null, where);

  const onBack = hasOwnerQuery ? goToUsers : null;

  return (
    <>
      <PageHeader title={`Dispositivi ${hasOwnerQuery ? "dell'utente" : ''}`} onBack={onBack} />
      <Input
        className="mac-search"
        placeholder="Cerca per MAC"
        prefix={MAC_ADDR_PREFIX}
        onChange={e => setQuery(e.target.value)}
        value={query}
        style={{ marginBottom: '1rem' }}
      />
      <DevicesList value={data} isLoading={loading} />
    </>
  );
}
