import * as firebase from 'firebase/app';
import 'firebase/functions';

export let firebaseConfig = {};

const prodConfig = {
  apiKey: 'AIzaSyAxAS49dIf9iM9wh-TdyAeq_qGIDIvNZc0',
  authDomain: 'tlab-iot.firebaseapp.com',
  databaseURL: 'https://tlab-iot.firebaseio.com',
  projectId: 'tlab-iot',
  storageBucket: 'tlab-iot.appspot.com',
  messagingSenderId: '311364457303',
  appId: '1:311364457303:web:2cd9a6893a7918db40a412',
};

const prodAdminConfig = {
  ...prodConfig,
  authDomain: 'admin.ti-do.app',
};

const stagingConfig = {
  apiKey: 'AIzaSyB_22sGgPIsDedhCQ030cQc4qY9Uhb8PDI',
  authDomain: 'tido-staging.firebaseapp.com',
  databaseURL: 'https://tido-staging.firebaseio.com',
  projectId: 'tido-staging',
  storageBucket: 'tido-staging.appspot.com',
  messagingSenderId: '22641146372',
  appId: '1:22641146372:web:94d9702d3dd4acc524d351',
};

if (process.env.REACT_APP_ENV === 'production') {
  if (window.location.hostname === prodAdminConfig.authDomain) {
    firebaseConfig = { ...prodAdminConfig };
  } else {
    firebaseConfig = { ...prodConfig };
  }
} else {
  firebaseConfig = { ...stagingConfig };
}

const firebaseApp = firebase.initializeApp(firebaseConfig);

export function getAuth() {
  return firebaseApp.auth();
}

export function getFirestore() {
  return firebaseApp.firestore();
}

export function getStorage() {
  return firebaseApp.storage();
}
