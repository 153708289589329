import React, { useCallback, useState } from 'react';
import { message, notification, PageHeader } from 'antd';
import FirmwareUploadForm from './FirmwareUploadForm';
import { getFirestore, getStorage } from '../../config';
import firebase from 'firebase';

export default function FirmwareUpload() {
  const [uploading, setUploading] = useState(false);

  const onSubmit = useCallback(({ dragger, ...data }) => {
    setUploading(true);
    const ext = dragger[0].name.split('.').pop();
    const ref = getStorage()
      .ref()
      .child('firmware')
      .child(data.boardModel)
      .child(`${data.versionNumber}.${ext}`);

    const uploadTask = ref.put(dragger[0].originFileObj);
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      null,
      error => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            message.error('Non hai i permessi per accedere a questo oggetto');
            break;

          case 'storage/canceled':
            message.warn('Upload cancellato');
            break;
          case 'storage/unknown':
            message.error('Si è verificato un errore');
            break;
          default:
            message.error('Si è verificato un errore imprevisto');
        }
        setUploading(false);
      },
      async () => {
        try {
          await uploadTask.snapshot.ref.getDownloadURL();
          await getFirestore()
            .collection('firmwares')
            .add({
              fileUpload: `/firmware/${data.boardModel}/${data.versionNumber}.${ext}`,
              ...data,
              notes: data.notes || '',
              releaseDate: firebase.firestore.FieldValue.serverTimestamp(),
            });
          message.success('firmware correttamente caricato');
          setUploading(false);
        } catch (e) {
          console.log(e);
          notification.error({ message: 'Qualcosa è andato storto nel caricamento' });
        }
      },
    );
  }, []);
  return (
    <>
      <PageHeader title="Firmware Upload" />
      <FirmwareUploadForm onSubmit={onSubmit} loading={uploading} />
    </>
  );
}
